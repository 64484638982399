function PublicSiteLogo({ height }: { height: number | undefined }) {
    let _height = height || 32;
    return (
        <svg
            width="50%"
            height="100%"
            viewBox="0 0 1142 211"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.0957031"
                width="152.096"
                height="37.7799"
                fill="#000901"
            />
            <rect y="58.2285" width="152.106" height="36.4399" fill="#000901" />
            <rect
                x="36.4854"
                y="69.0215"
                width="163.429"
                height="36.4399"
                transform="rotate(45 36.4854 69.0215)"
                fill="#000901"
            />
            <rect
                y="210.334"
                width="152.106"
                height="36.4399"
                transform="rotate(-90 0 210.334)"
                fill="#000901"
            />
            <path
                d="M215.996 111.027V61.324H258.528C267.43 61.324 274.106 63.3023 278.557 67.2587C283.008 71.0503 285.234 77.2322 285.234 85.8044C285.234 94.7064 283.008 101.136 278.557 105.092C274.106 109.048 267.43 111.027 258.528 111.027H215.996ZM177.174 31.1562V207.712H215.996V138.722H254.819C264.545 138.722 271.551 140.865 275.837 145.151C280.123 149.437 282.926 156.196 284.245 165.428C285.234 172.516 285.976 179.935 286.47 187.683C286.965 195.431 288.284 202.107 290.427 207.712H329.249C327.436 205.239 326.035 202.272 325.045 198.81C324.221 195.183 323.562 191.392 323.067 187.435C322.737 183.479 322.49 179.605 322.325 175.813C322.161 172.022 321.996 168.725 321.831 165.922C321.501 161.471 320.842 157.02 319.853 152.569C319.028 148.118 317.627 144.079 315.649 140.453C313.671 136.661 311.115 133.446 307.983 130.809C304.851 128.006 300.895 125.946 296.114 124.627V124.132C306.005 120.176 313.094 114.406 317.38 106.823C321.831 99.2398 324.056 90.2554 324.056 79.8698C324.056 73.1109 322.82 66.8465 320.347 61.0767C318.039 55.1421 314.577 49.9493 309.962 45.4983C305.511 41.0473 300.07 37.5855 293.641 35.1127C287.377 32.4751 280.288 31.1562 272.375 31.1562H177.174Z"
                fill="#000901"
            />
            <path
                d="M344.501 31.1562V207.712H379.614V140.7C379.614 127.677 381.757 118.363 386.044 112.758C390.33 106.988 397.254 104.103 406.815 104.103C415.222 104.103 421.074 106.741 424.372 112.016C427.669 117.126 429.317 124.957 429.317 135.507V207.712H464.43V129.078C464.43 121.165 463.689 113.994 462.205 107.565C460.886 100.971 458.496 95.4482 455.034 90.9973C451.572 86.3814 446.791 82.8371 440.692 80.3644C434.757 77.7267 427.092 76.4079 417.695 76.4079C411.101 76.4079 404.342 78.1389 397.418 81.6007C390.495 84.8978 384.807 90.2554 380.356 97.6737H379.614V31.1562H344.501Z"
                fill="#000901"
            />
            <path
                d="M569.714 130.067H512.593C512.758 127.594 513.253 124.792 514.077 121.66C515.066 118.527 516.632 115.56 518.775 112.758C521.083 109.955 524.051 107.647 527.677 105.834C531.469 103.856 536.167 102.867 541.772 102.867C550.344 102.867 556.691 105.174 560.812 109.79C565.099 114.406 568.066 121.165 569.714 130.067ZM512.593 152.322H604.828C605.487 142.431 604.663 132.952 602.355 123.885C600.047 114.818 596.255 106.741 590.98 99.652C585.87 92.5634 579.276 86.9584 571.198 82.8371C563.12 78.551 553.641 76.4079 542.761 76.4079C533.035 76.4079 524.133 78.1389 516.055 81.6007C508.142 85.0626 501.301 89.8433 495.531 95.9428C489.761 101.877 485.31 108.966 482.178 117.209C479.046 125.451 477.48 134.353 477.48 143.915C477.48 153.806 478.964 162.872 481.931 171.115C485.063 179.358 489.432 186.446 495.037 192.381C500.642 198.315 507.483 202.931 515.561 206.228C523.638 209.36 532.705 210.927 542.761 210.927C557.268 210.927 569.632 207.63 579.853 201.035C590.073 194.441 597.657 183.479 602.602 168.148H571.693C570.539 172.104 567.406 175.896 562.296 179.522C557.186 182.984 551.086 184.715 543.998 184.715C534.106 184.715 526.523 182.16 521.248 177.05C515.973 171.939 513.088 163.697 512.593 152.322Z"
                fill="#000901"
            />
            <path
                d="M662.745 79.8698V41.5419H627.631V79.8698H606.365V103.361H627.631V178.781C627.631 185.21 628.703 190.403 630.846 194.359C632.989 198.315 635.874 201.365 639.501 203.508C643.292 205.651 647.578 207.053 652.359 207.712C657.304 208.536 662.497 208.948 667.937 208.948C671.399 208.948 674.944 208.866 678.57 208.701C682.197 208.536 685.494 208.207 688.461 207.712V180.511C686.813 180.841 685.082 181.088 683.269 181.253C681.455 181.418 679.559 181.501 677.581 181.501C671.646 181.501 667.69 180.511 665.712 178.533C663.734 176.555 662.745 172.599 662.745 166.664V103.361H688.461V79.8698H662.745Z"
                fill="#000901"
            />
            <path
                d="M728.907 143.915C728.907 138.804 729.401 133.776 730.39 128.831C731.379 123.885 733.028 119.517 735.336 115.725C737.809 111.933 741.023 108.884 744.98 106.576C748.936 104.103 753.882 102.867 759.816 102.867C765.751 102.867 770.696 104.103 774.653 106.576C778.774 108.884 781.989 111.933 784.297 115.725C786.769 119.517 788.5 123.885 789.489 128.831C790.479 133.776 790.973 138.804 790.973 143.915C790.973 149.025 790.479 154.053 789.489 158.998C788.5 163.779 786.769 168.148 784.297 172.104C781.989 175.896 778.774 178.945 774.653 181.253C770.696 183.561 765.751 184.715 759.816 184.715C753.882 184.715 748.936 183.561 744.98 181.253C741.023 178.945 737.809 175.896 735.336 172.104C733.028 168.148 731.379 163.779 730.39 158.998C729.401 154.053 728.907 149.025 728.907 143.915ZM693.793 143.915C693.793 154.135 695.359 163.367 698.492 171.61C701.624 179.852 706.075 186.941 711.844 192.875C717.614 198.645 724.538 203.096 732.616 206.228C740.693 209.36 749.76 210.927 759.816 210.927C769.872 210.927 778.939 209.36 787.017 206.228C795.259 203.096 802.265 198.645 808.035 192.875C813.805 186.941 818.256 179.852 821.388 171.61C824.52 163.367 826.086 154.135 826.086 143.915C826.086 133.694 824.52 124.462 821.388 116.22C818.256 107.812 813.805 100.723 808.035 94.9537C802.265 89.019 795.259 84.4856 787.017 81.3535C778.939 78.0564 769.872 76.4079 759.816 76.4079C749.76 76.4079 740.693 78.0564 732.616 81.3535C724.538 84.4856 717.614 89.019 711.844 94.9537C706.075 100.723 701.624 107.812 698.492 116.22C695.359 124.462 693.793 133.694 693.793 143.915Z"
                fill="#000901"
            />
            <path
                d="M841.499 79.8698V207.712H876.612V150.096C876.612 144.327 877.189 138.969 878.343 134.023C879.497 129.078 881.393 124.792 884.03 121.165C886.833 117.373 890.46 114.406 894.911 112.263C899.361 110.12 904.802 109.048 911.231 109.048C913.374 109.048 915.599 109.213 917.907 109.543C920.215 109.708 922.193 109.955 923.842 110.285V77.6443C921.039 76.8201 918.484 76.4079 916.176 76.4079C911.725 76.4079 907.439 77.0673 903.318 78.3861C899.197 79.705 895.323 81.6007 891.696 84.0735C888.069 86.3814 884.855 89.2663 882.052 92.7282C879.25 96.0252 877.024 99.652 875.376 103.608H874.881V79.8698H841.499Z"
                fill="#000901"
            />
            <path
                d="M978.409 139.464L1001.41 74.677H1001.9L1024.16 139.464H978.409ZM982.118 31.1562L915.353 207.712H954.423L968.271 168.395H1034.29L1047.65 207.712H1087.95L1021.93 31.1562H982.118Z"
                fill="#000901"
            />
            <path
                d="M1103.18 31.1562V207.712H1142V31.1562H1103.18Z"
                fill="#000901"
            />
        </svg>
    );
}

export default PublicSiteLogo;
