import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
    ArrowPathIcon,
    Bars3Icon,
    BookmarkSquareIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    LifebuoyIcon,
    PhoneIcon,
    PlayIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "../Logo";
import PublicSiteLogo from "../PublicSiteLogo/PublicSiteLogo";
import { useRouter } from "next/router";
import useUserSession from "../../hooks/useUserSession";
import * as gtag from "../../utils/gtag";

const solutions = [
    {
        name: "Analytics",
        description:
            "Get a better understanding of where your traffic is coming from.",
        href: "#",
        icon: ChartBarIcon,
    },
    {
        name: "Engagement",
        description:
            "Speak directly to your customers in a more meaningful way.",
        href: "#",
        icon: CursorArrowRaysIcon,
    },
    {
        name: "Security",
        description: "Your customers' data will be safe and secure.",
        href: "#",
        icon: ShieldCheckIcon,
    },
    {
        name: "Integrations",
        description:
            "Connect with third-party tools that you're already using.",
        href: "#",
        icon: Squares2X2Icon,
    },
    {
        name: "Automations",
        description:
            "Build strategic funnels that will drive your customers to convert",
        href: "#",
        icon: ArrowPathIcon,
    },
];
const callsToAction = [
    { name: "Watch Demo", href: "#", icon: PlayIcon },
    { name: "Contact Sales", href: "#", icon: PhoneIcon },
];
const resources = [
    {
        name: "Help Center",
        description:
            "Get all of your questions answered in our forums or contact support.",
        href: "#",
        icon: LifebuoyIcon,
    },
    {
        name: "Guides",
        description:
            "Learn how to maximize our platform to get the most out of it.",
        href: "#",
        icon: BookmarkSquareIcon,
    },
    {
        name: "Events",
        description:
            "See what meet-ups and other events we might be planning near you.",
        href: "#",
        icon: CalendarIcon,
    },
    {
        name: "Security",
        description: "Understand how we take your privacy seriously.",
        href: "#",
        icon: ShieldCheckIcon,
    },
];
const recentPosts = [
    { id: 1, name: "Boost your conversion rate", href: "#" },
    {
        id: 2,
        name: "How to use search engine optimization to drive traffic to your site",
        href: "#",
    },
    { id: 3, name: "Improve your customer experience", href: "#" },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const handleSignIn = () => {
    gtag.event({
        action: "click_sign_in",
        category: "account",
        label: "click_sign_in",
        value: "SIGNIN",
    });
};

const handleSignUp = () => {
    gtag.event({
        action: "click_sign_up",
        category: "account",
        label: "click_sign_up",
        value: "SIGNUP",
    });
};

const handleGoToDashboard = () => {
    gtag.event({
        action: "click_go_to_dashboard",
        category: "account",
        label: "click_go_to_dashboard",
        value: "GO_TO_DASHBOARD",
    });
};

export default function PublicSiteHeader() {
    const { isSignedIn } = useUserSession();
    const router = useRouter();

    return (
        <Popover className="relative bg-white">
            <div className="mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a onClick={() => router.push("/")}>
                            <span className="sr-only">Your Company</span>
                            <PublicSiteLogo height={32} />
                        </a>
                    </div>
                    <div className="-my-2 -mr-2 md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                            <span className="sr-only">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 gap-8">
                        <a
                            // href="#pricing"
                            onClick={() => router.push("/#pricing")}
                            className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
                        >
                            Pricing
                        </a>
                        {!isSignedIn ? (
                            <>
                                <a
                                    onClick={() => router.push("/signin")}
                                    className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
                                >
                                    Sign in
                                </a>
                                <a
                                    onClick={() => router.push("/signup")}
                                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 cursor-pointer"
                                >
                                    Sign up
                                </a>
                            </>
                        ) : (
                            <a
                                onClick={() => router.push("/dashboard")}
                                className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 cursor-pointer"
                            >
                                Go to dashboard
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-20"
                >
                    <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="space-y-6 py-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <PublicSiteLogo height={32} />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Popover.Button>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500 ">

                                        <a
                                            href="#pricing"
                                        >
                                            Pricing
                                        </a>
                                    </p>
                                </Popover.Button>

                            </div>
                            {!isSignedIn ? (
                                <div>
                                    <a
                                        onClick={() => {
                                            router.push("/signup");
                                            handleSignUp;
                                        }}
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 cursor-pointer"
                                    >
                                        Sign up
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                        Existing customer?{" "}
                                        <a
                                            onClick={() => {
                                                router.push("/signin");
                                                handleSignIn;
                                            }}
                                            className="text-sky-600 hover:text-sky-700 cursor-pointer"
                                        >
                                            Sign in
                                        </a>
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    <a
                                        onClick={() => {
                                            router.push("/dashboard");
                                            handleGoToDashboard;
                                        }}
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sky-700 cursor-pointer"
                                    >
                                        Go to dashboard
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
